.infobar-container {
    margin-top: 5rem;
    width: 100%;
    height: 12rem;
    background: linear-gradient(to right, #fff 0%, #0093c0 30%, #0093c0 70%, #fff 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.infobar-info {
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
}
.infobar-subinfo {
    font-size: 1.2rem;
    color: #fff;
}