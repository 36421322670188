.qr-container {
    height: auto;
    width: 100%;
    padding: 2rem;
    display: flex;
    justify-content: center;
}
.qr-form-container {
    width: 30rem;
    height: auto;
    margin-right: .5rem;
}

.data-input,
.width-input,
.height-input {
    width: 23.5rem !important;
    border: 1px solid #ddd;
}

.input-group {
    justify-content: space-between;
}
.qr-preview-container {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    padding-bottom: 3rem;
}

.qr-side-container {
    display: flex;
    width: 10rem;
    margin-left: .5rem;
    flex-direction: column;
}

.download-btn {
    margin-top: .5rem;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    background-color: #ddd;
}

.accordion-button:not(.collapsed) {
    color: #000;
    background: #ddd;
    border-color: rgba(0,0,0,.125);
}