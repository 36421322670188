.titlebar-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 5rem;
    background-color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}
.titlebar-title-container-1 {
    position: relative;
    height: 100%;
    width: 8rem;
}
.letter {
    font-size: 4.3rem !important;
    top: -.8rem;
}
.titlebar-words {
    color: #fff;
    position: absolute;
    font-size: 1.9rem;
}
.word-1 {
    top: 0.4rem;
    left: 2.5rem;
}
.word-2 {
    top: 1.9rem;
    left: 2rem;
}